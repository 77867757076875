<template>
  <div>
    <h1 class="main-header" v-translate translate-context="customer_success.search">Search Users</h1>
    <CCard>
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <PFRow :label="$pgettext('customer_success.search','User ID')">
            <CInput v-model="form.user_id" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('customer_success.search','E-Mail')">
            <CInput v-model="form.email" type="email"/>
          </PFRow>
          <PFRow :label="$pgettext('customer_success.search','Pica Code')">
            <CInput v-model="form.pica_code"/>
          </PFRow>
          <PFRow>
            <PButton color="primary" @click="onSearch">Search</PButton>
          </PFRow>
        </CContainer>
      </CCardBody>
    </CCard>
    <div v-if="users.length > 0">
      <div v-for="user in users" :key="user.id">
        <h2>User ID: {{ user.id }}</h2>
        <p>Email: {{ user.email }}</p>
        <PButton color="primary" @click="openUser(user)">Select</PButton>
        <CButton @click="toggle(user)">Checkins</CButton>
        <CCollapse :show="Boolean(!user._toggled)">
          <CCard>
              <CCardBody>
                <div v-for="checkin in user.checkins" :key="checkin.id">
                  <p>Is Master: {{ checkin.is_master }}</p>
                  <p>Pica Service: {{ checkin.pica_service }}</p>
                  <p>Created At: {{ checkin.created_at }}</p>
                </div>
              </CCardBody>
          </CCard>
        </CCollapse>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: 'SearchUsers',
  components: {PButton, PFRow},
  data() {
    return {
      form: {
        user_id: '',
        email: '',
        pica_code: '',
      }
    }
  },
  computed: {
    ...mapGetters('customerSuccess', [
      'users',
    ]),
  },
  methods: {
    ...mapActions('customerSuccess', [
      'searchUsers',
    ]),
    async onSearch() {
      await this.searchUsers(this.form)
      if (this.users.length === 1)
        await this.openUser(this.users[0])
      else if (this.users.length === 0)
        await this.notifyError(this.$pgettext('customer_success.search', 'No User found'))
    },
    async openUser(user) {
      await this.$router.push({name: 'customer_success.user', params: {id: user.id}})
    },
    async toggle(item) {
      this.$set(item, "_toggled", !item._toggled)
      console.log('set', item._toggled)
    }
  }
}
</script>